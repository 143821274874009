// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animation-js": () => import("/opt/build/repo/src/pages/animation.js" /* webpackChunkName: "component---src-pages-animation-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quiz-js": () => import("/opt/build/repo/src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-results-js": () => import("/opt/build/repo/src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

